<template>
  <NuxtLink class="link" no-prefetch :to="`/${parentCategorySlug}/${article.slug}`">
    <article-card-generic class="article-card" :class="{ large }">
      <cms-components-shared-image
        fit-cover
        :image="article.cover"
        class="cover"
        width="260"
        height="180"
        size="xsmall"
      />
      <div class="content">
        <h3 class="title">{{ article.title }}</h3>

        <cms-components-rich-text class="intro" :text="article.intro" />
        <div class="published-at">{{ updatedAt }}</div>
      </div>
    </article-card-generic>
  </NuxtLink>
</template>

<script>
import { dateFormat } from '@hz/ui-kit'

export default {
  props: {
    article: {
      type: Object,
      required: true,
    },
    parentCategorySlug: {
      type: String,
      required: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    updatedAt() {
      return dateFormat(this.article.updatedAt ? this.article.updatedAt : new Date(), 'dd mmmm yyyy')
    },
  },
}
</script>

<style lang="less" scoped>
@card-width: 260px;

.article-card {
  height: 352px;
  width: @card-width;

  &.large {
    width: 100%;

    :deep(img) {
      width: 100%;
    }
  }
}

.cover {
  min-height: 180px;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 14px 24px;
}

.link {
  text-decoration: none;
}

.title {
  display: -webkit-box;
  white-space: initial;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  font-weight: var(--ds-weight-semi-bold);
  font-size: 16px;
  color: var(--ds-color-gray-100);
  margin-top: 0;
  margin-bottom: 4px;
}

.intro {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  height: 64px;
  overflow: hidden;

  font-weight: 400;
  font-size: 14px;
  color: var(--ds-color-gray-50);

  margin-bottom: 12px;

  :deep(strong) {
    font-weight: normal;
  }
}

.published-at {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.24px;
  color: var(--ds-color-gray-50);
}

@media @bp-desktop {
  .article-card {
    &.large {
      width: @card-width;

      :deep(img) {
        width: auto;
      }
    }
  }
}
</style>
